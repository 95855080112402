import { ReactChild } from 'react';
import Footer from 'app/components/Footer';
import NavBar from 'app/components/NavBar/NavBar';
import styled from 'styled-components';
import ConnectWalletDialog from 'app/components/ConnectWalletDialog';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

export default function LayoutContainer({ pageTitle, children }: { pageTitle: string; children: ReactChild }) {
  return (
    <Container>
      <NavBar />
      {children}
      <Footer />
      <ConnectWalletDialog />
    </Container>
  );
}
