import { Contract } from '@ethersproject/contracts';
import type { Signer } from '@ethersproject/abstract-signer';
import type { Provider } from '@ethersproject/providers';
import nftDropAbi from '../abi/NftDrop.json';
import { rpcProvider } from '../utils/provider';

export const getContract = (abi: any, address: string, signer: Signer | Provider) => {
  const signerOrProvider: any = signer ?? rpcProvider;
  return new Contract(address, abi, signerOrProvider);
};

export const getNftDropContract = (address: string, signer: Signer | Provider) => {
  return getContract(nftDropAbi, address, signer);
};
