import React from 'react';
import { Box, Typography, IconButton, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import { NftInfo } from '../../services/myNftService';
import OpenSeaButton from 'app/components/OpenSeaButton';
import Image from 'app/components/Image';

type Props = {
  open: boolean;
  onClose: () => void;
  nft: NftInfo;
};

function DialogNFT({ open, onClose, nft }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      data-testid="DialogNFT-dialog"
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{ sx: { maxWidth: { sm: 375 }, padding: '16px 16px 32px', minHeight: { md: '700px' } } }}
    >
      <DialogTitle id="alert-nft-detail-dialog-title" style={{ padding: '0' }}>
        <Grid container direction="row" justifyContent="end">
          <IconButton aria-label="close" onClick={onClose} sx={{ paddingTop: '0', paddingRight: '0' }}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: '0', display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Box height="343px">
          {nft.animationUrl ? (
            <video
              style={{ borderRadius: '16px' }}
              width="100%"
              height="100%"
              autoPlay
              loop
              controls
              controlsList="nodownload"
              playsInline
            >
              <source src={nft.animationUrl} type="video/mp4" data-testid="DialogNFT-icon-video" />
            </video>
          ) : (
            <Image src={nft.image} alt={nft.name} />
          )}
        </Box>
        <Box display="flex" flexDirection="column" height="100%" sx={{ gap: { xs: '23px', md: '8px' } }}>
          <Box display="flex" flexDirection="column">
            <Typography
              data-testid="DialogNFT-text-name"
              component="span"
              variant="subtitle2"
              sx={{ lineHeight: '25px' }}
            >
              {nft.name}
            </Typography>
            <Typography
              data-testid="DialogNFT-text-collectionName"
              component="span"
              variant="subtitle2"
              sx={{ lineHeight: '25px' }}
            >
              {nft.collectionName}
            </Typography>
            <Typography
              data-testid="DialogNFT-text-creator"
              component="span"
              variant="subtitle2"
              sx={{ lineHeight: '25px' }}
            >
              by {nft.creator}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">Token ID</Typography>
            <Typography data-testid="DialogNFT-text-token" variant="body2">
              {nft.tokenId}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">Description</Typography>
            <Typography data-testid="DialogNFT-text-description" variant="body2">
              {nft.description || '-'}
            </Typography>
          </Box>

          <OpenSeaButton
            contract={nft.contract}
            tokenId={nft.tokenId}
            buttonProps={{ sx: { mt: 3, py: '12px', borderRadius: '100px', height: '48px' } }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default DialogNFT;
