import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store/configureStore';

type WalletState = {
  isOpenConnectModal: boolean;
};

// Define the initial state using that type
const initialState: WalletState = {
  isOpenConnectModal: false,
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    openWalletModal: state => {
      state.isOpenConnectModal = true;
    },
    closeWalletModal: state => {
      state.isOpenConnectModal = false;
    },
  },
});

export const { openWalletModal, closeWalletModal } = walletSlice.actions;

export const walletSelector = (state: RootState) => state.wallet;

export default walletSlice.reducer;
