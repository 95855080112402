/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { HomePage } from './pages/HomePage/Loadable';
import { NotFoundPage } from './pages/NotFoundPage';
import { useTranslation } from 'react-i18next';
import CollectionPage from './pages/CollectionPage';
import MyCollectionsPage from './pages/MyCollectionsPage';
import NftListPage from './pages/NftListPage';
import ScrollToTop from 'utils/scrollToTop';

export function App() {
  const { i18n } = useTranslation();
  const titleName = 'Online Station - NFTs Marketplace';
  const description = 'Online Station - NFTs Marketplace';

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Suspense fallback={<div>Loading...</div>}>
          <Helmet htmlAttributes={{ lang: i18n.language }}>
            <title>{titleName}</title>
            <meta name="description" content={description} />
          </Helmet>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/my-collection" component={MyCollectionsPage} />
            <Route path="/collection/:id" component={CollectionPage} />
            <Route path="/support" component={HomePage} />
            <Route component={NotFoundPage} />
          </Switch>
          <GlobalStyle />
        </Suspense>
      </ScrollToTop>
    </BrowserRouter>
  );
}
