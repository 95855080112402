import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCollectionInfo, getNftInfo } from 'api/ipfsService';
import NftDropServices from 'smartcontract/service/nftDropServices';
import { ipfsToHttp } from 'utils/ipfs-util';

export type NftInfo = {
  contract: string;
  name: string;
  owner: string;
  tokenId: number;
  image: string;
  ipfs: string;
  description?: string;
};

export type NftDetail = Omit<
  NftInfo & {
    collectionName: string;
    creator: string;
  },
  'owner'
>;

export const fetchMyNfts = createAsyncThunk(
  'nft/fetchMyNfts',
  async ({ wallet, contracts }: { wallet: string; contracts: string[] }, { rejectWithValue }) => {
    try {
      let response: NftInfo[] = [];

      for (let i = 0; i < contracts.length; i++) {
        const contractInstance = new NftDropServices(contracts[i]);
        const balance = await contractInstance.getBalanceOf(wallet);

        const loop = parseInt(balance._hex, 16);
        if (loop === 0) continue;

        await Promise.all(
          Array(loop)
            .fill(0)
            .map(async (_, index) => {
              const nftId = await contractInstance.getTokenOfOwnerByIndex(wallet, index);
              const nftIpfs = await contractInstance.getTokenURI(parseInt(nftId._hex, 16));
              const nftInfo = await getNftInfo(nftIpfs.replace('ipfs://', ''));
              response.push({
                contract: contracts[i],
                name: nftInfo.name,
                owner: wallet,
                tokenId: parseInt(nftId._hex, 16),
                image: ipfsToHttp(nftInfo.image),
                ipfs: nftInfo.image.replace('ipfs://', ''),
                description: nftInfo.description,
              });
            }),
        );
      }

      return response;
    } catch (error: any) {
      return rejectWithValue({
        errorMessage: JSON.stringify(error),
      });
    }
  },
);

export const fetchNftDetail = createAsyncThunk(
  'nft/fetchNftDetail',
  async ({ contract, tokenId }: { contract: string; tokenId: number }, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();

      const existingState: NftDetail[] = state?.nft?.nftDetail;
      if (existingState.find(value => value.contract === contract && value.tokenId === tokenId)) return existingState;

      let response: NftDetail[] = Object.assign([], existingState);

      const contractInstance = new NftDropServices(contract);
      const [nftIpfs, uri] = await Promise.all([
        contractInstance.getTokenURI(tokenId),
        contractInstance.getContractURI(),
      ]);

      const [collectionInfo, nftInfo] = await Promise.all([
        getCollectionInfo(uri.replace('ipfs://', '')),
        getNftInfo(nftIpfs.replace('ipfs://', '')),
      ]);

      const splitName = collectionInfo.name.split('-');

      response.push({
        contract: contract,
        name: nftInfo.name,
        tokenId: tokenId,
        image: ipfsToHttp(nftInfo.image),
        ipfs: nftInfo.image.replace('ipfs://', ''),
        description: nftInfo.description,
        creator: splitName?.[1]?.trim() || '',
        collectionName: splitName?.[0]?.trim() || collectionInfo.name,
      });

      return response;
    } catch (error: any) {
      return rejectWithValue({
        errorMessage: JSON.stringify(error),
      });
    }
  },
);

export const fetchNftOwner = createAsyncThunk(
  'nft/fetchNftOwner',
  async ({ contract, tokenId }: { contract: string; tokenId: number }, { rejectWithValue }) => {
    try {
      const contractInstance = new NftDropServices(contract);
      return await contractInstance.getOwnerOf(tokenId);
    } catch (error: any) {
      return rejectWithValue({
        errorMessage: JSON.stringify(error),
      });
    }
  },
);
