import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TernAndConditionPdf from 'assets/pdf/NFT-Dropsite-Website-T&Cs.pdf';
import PrivacyNoticePdf from 'assets/pdf/Privacy-Notice-Online-Station.pdf';
interface CheckBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-testid'?: string;
}

type NFTModalProps = {
  isOpen: boolean;
  handleClaim: () => void;
  handleClose: () => void;
  itemImg: string;
  name: string;
  creator: string;
  price: number;
  totalClaimed: number;
  totalSupply: number;
};

const NFTModal = ({
  isOpen,
  handleClaim,
  handleClose,
  itemImg,
  name,
  creator,
  price,
  totalClaimed,
  totalSupply,
}: NFTModalProps) => {
  const [acceptTermsAndCondition, setAcceptTermsAndCondition] = useState<boolean>(false);
  const [disableConfirm, setDisableConfirm] = useState<boolean>(false);
  const handleAcceptTermsAndCondition = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptTermsAndCondition(event.target.checked);
  };

  useEffect(() => {
    setAcceptTermsAndCondition(false);
    setDisableConfirm(false);
  }, [isOpen]);

  return (
    <Dialog
      data-testid="NFTModal-dialog"
      id="alert-claim-dialog"
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: '16px',
          width: '343px',
          padding: '16px 16px 32px',
        },
      }}
    >
      <DialogTitle id="alert-claim-dialog-title" style={{ padding: '0' }}>
        <Grid container direction="row" justifyContent="end">
          <IconButton aria-label="close" onClick={handleClose} sx={{ paddingTop: '0', paddingRight: '0' }}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={{
          borderRadius: '16px',
          padding: '0',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          overflowY: 'overlay',
        }}
      >
        <Box sx={{ height: '311px' }}>
          <img
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '1rem',
            }}
            src={itemImg}
            srcSet={itemImg}
            alt="claim-nft"
            loading="lazy"
          />
        </Box>
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography data-testid="NFTModal-text-name" variant="subtitle2">
            {name}
            <Typography
              data-testid="NFTModal-text-supply"
              component="span"
              variant="subtitle2"
              color="customColor.grey.accent2"
              sx={{ marginLeft: '0.5rem' }}
            >
              {totalClaimed === totalSupply && `( ${totalClaimed - 1} / ${totalSupply - 1} )`}
            </Typography>
          </Typography>
          <Typography data-testid="NFTModal-text-creator" variant="subtitle2">
            by {creator}
          </Typography>
          <Typography variant="body2" data-testid="NFTModal-text-price">
            {price > 0 ? `${price} ETH` : 'Free Airdrop'}
          </Typography>
        </Box>
        <Box display="flex" alignItems="flex-start">
          <Checkbox
            id="chk_popupTermAndCondition"
            color="success"
            inputProps={{ 'data-testid': 'NFTModal-checkbox' } as CheckBoxProps}
            onChange={handleAcceptTermsAndCondition}
            sx={{ marginTop: '-0.5rem' }}
            disabled={disableConfirm}
          />
          <Typography variant="subtitle2">
            By clicking "Confirm" , you agree with Online Station{' '}
            <Typography
              component="a"
              color="customColor.blue.accent2"
              href={TernAndConditionPdf}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                marginRight: '0.5rem',
                textDecoration: 'none',
              }}
            >
              Term and condition
            </Typography>
            and
            <Typography
              component="a"
              color="customColor.blue.accent2"
              href={PrivacyNoticePdf}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                marginLeft: '0.5rem',
                textDecoration: 'none',
              }}
            >
              Privacy Notice
            </Typography>
          </Typography>
        </Box>
      </DialogContent>
      <Button
        onClick={() => {
          setDisableConfirm(true);
          handleClaim();
        }}
        variant="contained"
        fullWidth
        sx={{ borderRadius: '100px', height: '48px', marginTop: '16px' }}
        disabled={!acceptTermsAndCondition || disableConfirm}
      >
        CONFIRM
      </Button>
    </Dialog>
  );
};

export default NFTModal;
