import * as React from 'react';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

export function NotFoundPage() {
  const history = useHistory();

  return (
    <Dialog
      data-testid="NotfoundPageModal-dialog"
      open
      PaperProps={{
        style: { borderRadius: '16px', padding: '16px', width: '327px' },
      }}
    >
      <DialogContent
        style={{
          padding: '0',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '24px',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            height: '160px',
          }}
        >
          <img
            style={{
              height: '160px',
              objectFit: 'cover',
              borderRadius: '1rem',
              left: 0,
              right: 0,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            src="/images/box-bg.svg"
            srcSet="/images/box-bg.svg"
            alt="not-found-bg"
            loading="lazy"
          />
          <img
            style={{
              width: '154.3px',
              objectFit: 'cover',
              borderRadius: '1rem',
              left: 0,
              right: 0,
              marginLeft: 'auto',
              marginRight: 'auto',
              position: 'absolute',
            }}
            src="/images/box.svg"
            srcSet="/images/box.svg"
            alt="not-found-box"
            loading="lazy"
          />
        </Box>
        <Typography variant="subtitle1">Oops!</Typography>
        <Typography variant="body2">We're sorry! The page you requested could not be found. </Typography>
        <Button
          data-testid="NotfoundPageModal-btn-ok"
          onClick={() => history.push('/')}
          variant="contained"
          fullWidth
          sx={{ borderRadius: '24px', height: '48px' }}
        >
          GO TO HOME
        </Button>
      </DialogContent>
    </Dialog>
  );
}
