import React from 'react';
import { Button } from '@mui/material';
import { useWeb3React } from '@web3-react/core';

const ConnectWalletButton = ({
  onClick,
  style,
  id,
}: {
  onClick: Function;
  style?: React.CSSProperties;
  id?: string;
}) => {
  const { account, active } = useWeb3React();
  return (
    <Button
      id={id}
      style={style}
      onClick={() => {
        onClick();
      }}
      sx={{ my: 2, textTransform: 'none', borderRadius: '100px' }}
      className="connect-wallet-btn"
      variant="contained"
    >
      {active ? `${account?.substring(0, 5)}...${account?.substring(account?.length - 5)}` : 'CONNECT WALLET'}
    </Button>
  );
};
export default ConnectWalletButton;
