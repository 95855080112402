import { useEffect } from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import LayoutContainer from 'app/containers/LayoutContainer';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import { useAppSelector, useAppDispatch } from 'redux/hook';
import {
  fetchCollectionList,
  fetchSupplyCollectionInfo,
  fetchActiveClaimConditionInfo,
} from 'redux/slices/collections/action';
import CheckIcon from 'assets/images/check.svg';
import Rarity from 'app/components/Rarity/Rarity';
import ClaimItemsSection from './components/ClaimItemsSection';
import Timer from 'app/components/Timer/Timer';
import ClampLines from 'react-clamp-lines';
import { useHistory } from 'react-router-dom';
import { copyText } from 'utils/copyText';
import FaqPdf from 'assets/pdf/FAQ.pdf';
import { trackVisitCollectionPage } from 'mixpanel';

const HeaderContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  boxShadow: 'none',
  color: theme.palette.text.secondary,
}));

export default function CollectionPage() {
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { collectionList, supplyInfo } = useAppSelector(state => state.collections);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const DEV_ADDRESS = process.env.REACT_APP_DEV_ADDRESS?.split(' ') ?? [];
  const COLLECTION_ADDRESS = [process.env.REACT_APP_NFT_DROP_ADDRESS || '', ...DEV_ADDRESS];

  useEffect(() => {
    if (id) {
      if (COLLECTION_ADDRESS.find(addr => addr === id)) {
        dispatch(fetchCollectionList({ contracts: [id || ''] }));
        dispatch(fetchSupplyCollectionInfo({ contract: id || '' }));
        dispatch(fetchActiveClaimConditionInfo({ contract: id || '' }));
      } else {
        history.push('/not-found');
      }
    }
  }, [dispatch, id]);

  const collectionInfo = collectionList.find(collection => collection.address === id);

  useEffect(() => {
    if (collectionInfo?.address === id) trackVisitCollectionPage(id, collectionInfo.name);
  }, [collectionInfo]);

  const copyContractAddress = async () => {
    await copyText(id);
    enqueueSnackbar('Contract Address copied');
  };

  const renderTimerSection = () => {
    return (
      <>
        <Typography variant="body2" sx={{ color: 'customColor.grey.accent2', mb: '10px' }}>
          Start in
        </Typography>
        <Box sx={{ mb: '16px' }}>
          <Timer title="Whitelist" date={collectionInfo?.privateDate as Date} />
        </Box>
        <Box>
          <Box
            p="1rem"
            bgcolor="customColor.grey.accent4"
            borderRadius="1rem"
            width="100%"
            textAlign="center"
            data-testid="timer"
          >
            <Box data-testid="CardCollection-text-time" sx={{ marginBottom: '0.5rem' }}>
              <Typography variant="subtitle2">Public Sales</Typography>
              <Typography variant="body2" style={{ paddingLeft: '0.5rem', paddingTop: '0.5rem' }}>
                21 October - 25 November 2022 at 18.00
              </Typography>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <LayoutContainer pageTitle="All Collection">
      {!collectionInfo ? (
        <Box
          data-testid="progress-loading"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: { xs: 'calc(100vh - 348px)', md: 'calc(100vh - 364px)' },
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <HeaderContainer>
            <Box>
              <Box
                id="img_coverArtist_desktop_seeCollectionPage"
                component="img"
                sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}
                src={collectionInfo?.bgDesktopImage}
                srcSet={collectionInfo?.bgDesktopImage}
                alt="cover-artist"
                loading="lazy"
              />
              <Box
                id="img_coverArtist_mobile_seeCollectionPage"
                component="img"
                sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}
                src={collectionInfo?.bgMobileImage}
                srcSet={collectionInfo?.bgMobileImage}
                alt="cover-artist"
                loading="lazy"
              />
            </Box>
            <Box sx={{ position: 'relative', px: '0px' }}>
              <Box sx={{ position: 'absolute', width: '100%', bottom: '-40px' }}>
                <Box
                  id="img_artist-picProfile_seeCollectionPage"
                  component="img"
                  sx={{
                    borderRadius: '50%',
                    border: '2px solid',
                    borderColor: 'customColor.grey.accent2',
                    width: '120px',
                    height: '120px',
                    backgroundColor: 'white',
                  }}
                  src={collectionInfo?.avatarImage}
                  srcSet={collectionInfo?.avatarImage}
                  alt="nft-profile"
                  loading="lazy"
                />
              </Box>
            </Box>
            <Grid
              container
              alignItems={'center'}
              justifyContent="center"
              rowSpacing={'16px'}
              padding={'0 2rem'}
              sx={{ mt: '48px' }}
            >
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography id="lbl_collectionName" variant="h5" fontWeight={'bold'}>
                  {collectionInfo.name}
                </Typography>
                <Box
                  component="img"
                  src={CheckIcon}
                  alt="check-icon"
                  sx={{ width: '24px', height: '24px', ml: '8px' }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography id="lbl_artist-name" variant="subtitle2">
                  by {collectionInfo.by}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={copyContractAddress}
                  disabled={!id}
                  sx={{ borderColor: 'customColor.grey.accent2', fontSize: '0.75rem' }}
                >
                  <Typography component={'span'} variant="inherit" sx={{ color: 'customColor.grey.accent2' }}>
                    Address
                  </Typography>
                  <Typography
                    id="lbl_addressOfArtist"
                    component={'span'}
                    variant="inherit"
                    sx={{ ml: '10px', color: 'customColor.blue.accent2' }}
                  >
                    {id?.substring(0, 5)}...
                    {id?.substring(id?.length - 5)}
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: '16px', display: 'flex', justifyContent: 'center' }}>
                {collectionInfo.description && (
                  <Box data-testid="CollectionPage-box-desc" textAlign="center" sx={{ maxWidth: '741px' }}>
                    <ClampLines
                      id="CollectionPage-text-desc"
                      text={collectionInfo.description}
                      moreText="See more"
                      lessText="Show less"
                      innerElement="p"
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
            <Box pb="16px">
              <Typography
                component="a"
                variant="body2"
                target="_blank"
                color="customColor.blue.accent2"
                sx={{ textDecoration: 'none' }}
                href={FaqPdf}
                rel="noopener noreferrer"
              >
                Learn more
              </Typography>
            </Box>
            <Container maxWidth="xs" sx={{ mb: '32px' }}>
              {renderTimerSection()}
            </Container>
            <Container maxWidth="md" sx={{ mb: '40px' }}>
              <Rarity data={collectionInfo.rarity} />
            </Container>
          </HeaderContainer>

          <Box bgcolor="background.grey" pt="2rem" pb="2rem">
            {process.env.REACT_APP_UNDER_CONSTRUCTION === 'true' ? (
              <Box
                display="flex"
                mt="32px"
                flexDirection="column"
                alignItems="center"
                minHeight="300px"
                sx={{ gap: '16px' }}
              >
                <Box textAlign="center">
                  <Typography variant="body1">Site is under construction</Typography>
                  <Typography variant="body1">We will get back to you as soon as possible</Typography>
                </Box>
                <Box textAlign="center">
                  <Typography variant="body1">for more inquiry</Typography>
                  <Typography
                    color="#000000"
                    sx={{ textDecoration: 'none' }}
                    component="a"
                    variant="body1"
                    href="mailto:os-nft-support@truedigital.com"
                  >
                    os-nft-support@truedigital.com
                  </Typography>
                </Box>
              </Box>
            ) : (
              <>
                <Container sx={{ marginBottom: '2rem' }}>
                  <Typography id="lbl_collections" variant="h4">
                    All Collections
                  </Typography>
                </Container>
                <ClaimItemsSection
                  contract={collectionInfo?.address}
                  itemImg={collectionInfo.cliamImage || ''}
                  name={collectionInfo.name}
                  creator={collectionInfo.by}
                  totalClaimed={supplyInfo.totalClaimed}
                  totalSupply={supplyInfo.totalSupply}
                  startDate={collectionInfo.privateDate as Date}
                />
              </>
            )}
          </Box>
        </>
      )}
    </LayoutContainer>
  );
}
