import { mixpanel } from './mixpanelConfig';

export const trackVisitOnlineStationHomePage = () => {
  mixpanel.track('Visit Online Station Homepage');
};

export const trackVisitMyCollectionPage = () => {
  mixpanel.track('Visit My Collection');
};

export const trackVisitCollectionPage = (collectionAddress: string, collectionName: string) => {
  mixpanel.track('Visit Collection', {
    'Collection Name': collectionName,
    'Collection Address': collectionAddress,
  });
};

export const trackTapMint = (collectionAddress: string, collectionName: string) => {
  mixpanel.track('Tap Mint', {
    'Collection Name': collectionName,
    'Collection Address': collectionAddress,
  });
};

export const trackTapCheckWhitelist = (collectionAddress: string, collectionName: string) => {
  mixpanel.track('Tap Check Whitelist', {
    'Collection Name': collectionName,
    'Collection Address': collectionAddress,
  });
};
