import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

function NotFoundNFT() {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ minHeight: '40vh' }}>
      <Typography variant="subtitle2">No NFT Yet</Typography>
      <Typography variant="body2" color="customColor.grey.accent2" mt={2}>
        Your NFT will be shown here
      </Typography>
      <Button
        data-testid="NotFoundNFT-button"
        id="btn_explore"
        variant="text"
        component={Link}
        to="/"
        sx={{ mt: '24px' }}
      >
        <Typography component={'span'} variant="subtitle2" fontSize="medium" color="red">
          SEE COLLECTION
        </Typography>
      </Button>
    </Box>
  );
}

export default NotFoundNFT;
