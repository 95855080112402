import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

type InProgressModalProps = {
  title: string;
  desc: string;
  middleDesc?: string;
  isOpen: boolean;
};

const InProgressModal = ({ isOpen, title, middleDesc, desc }: InProgressModalProps) => {
  return (
    <Dialog
      data-testid="InProgressModal-dialog"
      open={isOpen}
      PaperProps={{
        style: { borderRadius: '16px', padding: '24px', width: '342px' },
      }}
    >
      <DialogContent style={{ padding: '0' }}>
        <Box textAlign="center" mb="1rem">
          <CircularProgress size="65px" />
        </Box>
        <Box
          sx={{ gap: '0.1rem' }}
          textAlign="center"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Typography variant="subtitle1">{title}</Typography>
          {middleDesc && <Typography variant="body2">{middleDesc}</Typography>}
          <Typography variant="body2">{desc}</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default InProgressModal;
