import { Fragment } from 'react';
import { Typography, Box, Grid } from '@mui/material';
import styles from './Rarity.module.css';

type RariryData = {
  amount: number;
  img: string;
  level: string;
};

export default function Rarity({ data }: { data: RariryData[] }) {
  const getBgColor = (index: number) => {
    if (index === 1) {
      return 'customColor.pink.main';
    } else if (index === 2) {
      return 'customColor.grey.accent3';
    } else if (index === 3) {
      return 'customColor.orange.main';
    }
    return 'customColor.grey.accent2';
  };

  const renderRarityNft = (item: RariryData, index: number) => {
    return (
      <>
        <Grid item xs={5} className={styles.column} id={`nft-image-${item.level}`}>
          <Box
            component="img"
            src={item.img}
            alt={`rarity-${item.level}`}
            loading="lazy"
            sx={{ width: '32px', height: '32px' }}
          />
        </Grid>
        <Grid item xs={5} className={styles.column} id={`level-${item.level}`}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', minWidth: '80px' }}>
            <Typography
              variant="body2"
              sx={{
                p: '8px',
                bgcolor: getBgColor(index),
                borderRadius: '100px',
                fontWeight: '600',
                fontSize: '0.75rem',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {item.level}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} className={styles.column} id={`amount-${item.level}`}>
          <Typography variant="body2">{item.amount.toLocaleString()}</Typography>
        </Grid>
      </>
    );
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      rowSpacing="32px"
      columnSpacing="16px"
      padding={'0 2rem'}
    >
      <Grid item xs={5} className={styles.column} id="nft-image-header">
        <Typography variant="body2" sx={{ color: 'customColor.grey.accent2' }}>
          NFT
        </Typography>
      </Grid>
      <Grid item xs={5} className={styles.column} id="rariry-header">
        <Typography variant="body2" sx={{ color: 'customColor.grey.accent2' }}>
          Rarity
        </Typography>
      </Grid>
      <Grid item xs={2} className={styles.column} id="amount-header">
        <Typography variant="body2" sx={{ color: 'customColor.grey.accent2' }}>
          Amount
        </Typography>
      </Grid>
      {data?.map((item, index) => (
        <Fragment key={item.level}>{renderRarityNft(item, index)}</Fragment>
      ))}
    </Grid>
  );
}
