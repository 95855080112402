import CollectionsSlice from '../slices/collections';
import NftSlice from '../slices/nft';
import WalletSlice from '../slices/wallet';

const rootReducer = {
  collections: CollectionsSlice,
  nft: NftSlice,
  wallet: WalletSlice,
};

export default rootReducer;
