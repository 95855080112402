import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type NotWhitelistModalProps = {
  isOpen: boolean;
  handleClose: () => void;
};

const NotWhitelistModal = ({ isOpen, handleClose }: NotWhitelistModalProps) => {
  return (
    <Dialog
      data-testid="NotWhitelistModal-dialog"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-not-wl-dialog"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: '16px', padding: '24px', height: '240px', width: '342px' },
      }}
    >
      <DialogTitle id="alert-not-wl-dialog-title" style={{ padding: '0' }}>
        <Grid container direction="row" justifyContent="end">
          <IconButton aria-label="close" sx={{ width: '24px', height: '24px' }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: '0' }}>
        <Box textAlign="center" display="flex" flexDirection="column" justifyContent="space-between" height="100%">
          <Typography variant="subtitle1">Oops, there was an issue</Typography>
          <Typography variant="body2">Sorry, you are not in the whitelist. Please confirm back later</Typography>
          <Button
            data-testid="NotWhitelistModal-btn-ok"
            onClick={handleClose}
            variant="contained"
            fullWidth
            sx={{ borderRadius: '24px', height: '48px' }}
          >
            GOT IT
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default NotWhitelistModal;
