import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'Manrope', Helvetica, Arial, sans-serif;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    font-family: Manrope, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  .clamp-lines > p {
    color: #8C8C8A;
    margin: 0;
  }

  .clamp-lines .clamp-lines__button {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #51A9F6;
    font-size: 1rem;
  }
`;
