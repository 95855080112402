import { useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import { getNftDropContract } from '../utils/contractHelper';

export const useNftContract = (contract: string) => {
  const address = contract;
  const { library, account } = useWeb3React();
  return useMemo(
    () => getNftDropContract(address, account ? library.getSigner(account).connectUnchecked() : null),
    [library, account],
  );
};
