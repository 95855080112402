/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// Use consistent styling
import 'sanitize.css/sanitize.css';

// Import root app
import { App } from 'app/App';
import './index.css';
import { HelmetProvider } from 'react-helmet-async';

import { store } from 'redux/store/configureStore';

import reportWebVitals from 'reportWebVitals';

// Initialize languages
import './locales/i18n';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { CssBaseline } from '@mui/material';
import { CreateCustomTheme } from 'theme';
import CookiesModal from 'app/components/CookiesModal/CookiesModal';
import { Web3ReactProvider } from '@web3-react/core';
import { getLibrary } from '../src/smartcontract/utils/connector';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const theme = CreateCustomTheme();

ReactDOM.render(
  <Provider store={store}>
    <Web3ReactProvider getLibrary={getLibrary}>
      <HelmetProvider>
        <React.StrictMode>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider maxSnack={3}>
              <CookiesModal />
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </React.StrictMode>
      </HelmetProvider>
    </Web3ReactProvider>
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
