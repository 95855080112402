import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store/configureStore';
import { NftInfo, fetchMyNfts, fetchNftDetail, NftDetail, fetchNftOwner } from './action';

const initialState: {
  myNfts: NftInfo[];
  nftDetail: NftDetail[];
  owner: string;
  isLoading: boolean;
  errorMessage?: string;
  isLoadingOwner: boolean;
  isLoadingDetail: boolean;
} = {
  myNfts: [],
  nftDetail: [],
  owner: '',
  isLoading: false,
  isLoadingOwner: false,
  isLoadingDetail: false,
  errorMessage: '',
};

const NftSlice = createSlice({
  name: 'nft',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchMyNfts.pending, state => {
        state.errorMessage = '';
        state.isLoading = true;
      })
      .addCase(fetchMyNfts.fulfilled, (state, { payload }) => {
        state.myNfts = payload;
        state.errorMessage = '';
        state.isLoading = false;
      })
      .addCase(fetchMyNfts.rejected, (state, { payload }: any) => {
        state.errorMessage = payload.errorMessage;
        state.isLoading = false;
      })
      .addCase(fetchNftDetail.pending, state => {
        state.errorMessage = '';
        state.isLoadingDetail = true;
      })
      .addCase(fetchNftDetail.fulfilled, (state, { payload }) => {
        state.nftDetail = payload;
        state.errorMessage = '';
        state.isLoadingDetail = false;
      })
      .addCase(fetchNftDetail.rejected, (state, { payload }: any) => {
        state.errorMessage = payload.errorMessage;
        state.isLoadingDetail = false;
      })
      .addCase(fetchNftOwner.pending, state => {
        state.errorMessage = '';
        state.isLoadingOwner = true;
      })
      .addCase(fetchNftOwner.fulfilled, (state, { payload }) => {
        state.owner = payload;
        state.errorMessage = '';
        state.isLoadingOwner = false;
      })
      .addCase(fetchNftOwner.rejected, (state, { payload }: any) => {
        state.errorMessage = payload.errorMessage;
        state.isLoadingOwner = false;
      });
  },
});

export const nftSelector = (state: RootState) => state.nft;

export default NftSlice.reducer;
