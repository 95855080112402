import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Typography, Box } from '@mui/material';
import MainButton from '../Button/MainButton';
import SecondaryButton from '../Button/SecondaryButton';
import CookiesImage from 'assets/images/cookies.svg';
import CookiesPdf from 'assets/pdf/cookies-policy.pdf';

export default function CookiesModal() {
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('acceptedCookies') || sessionStorage.getItem('showCookies')) {
      return;
    }
    setOpenDialog(true);
  }, []);

  const handleClickAccept = () => {
    localStorage.setItem('acceptedCookies', 'true');
    setOpenDialog(false);
  };

  const handleClickDecline = () => {
    sessionStorage.setItem('showCookies', 'true');
    setOpenDialog(false);
  };

  return (
    <Dialog open={openDialog} aria-describedby="cookies-dialog-description">
      <DialogContent
        sx={{
          maxWidth: '327px',
          borderRadius: '16px',
        }}
      >
        <Box component="img" src={CookiesImage} sx={{ width: '280px' }} />
        <Box sx={{ mb: '24px', textAlign: 'center' }}>
          <Typography variant="subtitle1" sx={{ mb: '16px' }}>
            Have a Cookie :)
          </Typography>
          <Typography align="center" variant="body2">
            {
              'Our website uses cookies to provide you efficiency and improve your browsing experience. You may find further details at '
            }
            <Typography
              component="a"
              href={CookiesPdf}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: 'customColor.blue.accent2', textDecoration: 'none' }}
            >
              Cookies Policy
            </Typography>
            {' and manage your Analytics Cookies preference by pressing the “Accept” or “Decline” button'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <MainButton sx={{ mb: '16px', width: '100%' }} onClick={handleClickAccept}>
            Accept
          </MainButton>
          <SecondaryButton sx={{ mb: '16px', width: '100%' }} onClick={handleClickDecline}>
            Decline
          </SecondaryButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
