import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

type SuccessClaimModalProps = {
  isOpen: boolean;
  handleClose: () => void;
};

const SuccessClaimModal = ({ isOpen, handleClose }: SuccessClaimModalProps) => {
  const history = useHistory();

  return (
    <Dialog
      data-testid="SuccessClaimModal-dialog"
      open={isOpen}
      aria-labelledby="alert-not-wl-dialog"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: '16px', padding: '24px', width: '327px' },
      }}
    >
      <DialogContent
        style={{
          padding: '0',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '24px',
          textAlign: 'center',
        }}
      >
        <Box sx={{ height: '160px' }}>
          <img
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '1rem',
            }}
            src="/images/nft/icon-wallet-295x160.png"
            srcSet="/images/nft/icon-wallet-295x160.png"
            alt="success-claim"
            loading="lazy"
          />
        </Box>
        <Typography variant="subtitle1">Congratulations!</Typography>
        <Typography variant="body2">The NFT Should Take 5-10 Minutes to appear to My Collection</Typography>
        <Button
          data-testid="SuccessClaimModal-btn-ok"
          onClick={() => history.push('/my-collection')}
          variant="contained"
          fullWidth
          sx={{ borderRadius: '24px', height: '48px' }}
        >
          SEE MY COLLECTION
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessClaimModal;
