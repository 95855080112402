// should move to dynamic data instead

enum RARITY {
  NORMAL = 'Normal',
  RARE = 'Rare',
  SUPER_RARE = 'Super Rare',
  SPECIAL_SUPER_RARE = 'Special Super Rare',
}

export const THAILAND_GAME_SHOW = {
  bgImage: '/images/thailand-game-show-bg.jpeg',
  bgDesktopImage: '/images/nft/Collection-Cover-Desktop.jpg',
  bgMobileImage: '/images/nft/Collection-Cover-Mobile.jpg',
  avatarImage: '/images/nft/Profile-NFT.png',
  cliamImage: '/images/nft/TGS-NFT.gif',
  by: 'Thailand Game Show',
  rarity: [
    {
      img: '/images/nft/TGS-NORMAL.png',
      level: RARITY.NORMAL,
      amount: 1000,
    },
    {
      img: '/images/nft/TGS-RARE.png',
      level: RARITY.RARE,
      amount: 800,
    },
    {
      img: '/images/nft/TGS-SUPER-RARE.png',
      level: RARITY.SUPER_RARE,
      amount: 200,
    },
    {
      img: '/images/nft/TGS-SPEACIAL-SUPER-RARE.png',
      level: RARITY.SPECIAL_SUPER_RARE,
      amount: 22,
    },
  ],
};

export const META_DATA = {
  [process.env.REACT_APP_NFT_DROP_ADDRESS || '']: THAILAND_GAME_SHOW,
};
