import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';

type TransactionErrorModalProps = {
  title: string;
  desc: string;
  isOpen: boolean;
  handleClose: () => void;
};

const TransactionErrorModal = ({ isOpen, handleClose, title, desc }: TransactionErrorModalProps) => {
  return (
    <Dialog
      data-testid="CheckWlStatusModal-dialog"
      open={isOpen}
      PaperProps={{
        style: { borderRadius: '16px', padding: '16px', width: '342px' },
      }}
    >
      <DialogContent style={{ padding: '0' }}>
        <Box
          sx={{ gap: '16px' }}
          textAlign="center"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Typography data-testid="CheckWlStatusModal-text-title" variant="subtitle1">
            {title}
          </Typography>
          <Typography data-testid="CheckWlStatusModal-text-desc" variant="body2">
            {desc}
          </Typography>
          <Button
            data-testid="CheckWlStatusModal-btn-close"
            onClick={handleClose}
            variant="contained"
            fullWidth
            sx={{ borderRadius: '24px', height: '48px', marginTop: '8px' }}
          >
            CLOSE
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TransactionErrorModal;
