import axios, { AxiosInstance } from 'axios';
import { apiEndpoint } from './config';
import interceptor from './interceptor/interceptor';

const IpfsAPI: AxiosInstance = axios.create({
  baseURL: apiEndpoint.ipfs,
  headers: {
    'content-type': 'application/json',
  },
});

IpfsAPI.interceptors.request.use(interceptor);

export default IpfsAPI;
