import { Box, Typography } from '@mui/material';
import { transformDateToDisplay } from 'utils/date-transform';
import Countdown from 'react-countdown';

const Timer = ({ title, date }: { title: string; date: Date }) => {
  const rendererCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <Box display="flex" gap="0.5rem" justifyContent="center" alignItems="center">
        <Typography variant="subtitle2">{('0' + days).slice(-2)}</Typography>
        <Typography variant="body2">Days</Typography>
        <Typography variant="subtitle2">{('0' + hours).slice(-2)}</Typography>
        <Typography variant="body2">Hours</Typography>
        <Typography variant="subtitle2">{('0' + minutes).slice(-2)}</Typography>
        <Typography variant="body2">Mins</Typography>
        <Typography variant="subtitle2">{('0' + seconds).slice(-2)}</Typography>
        <Typography variant="body2">Secs</Typography>
      </Box>
    );
  };

  return (
    <Box
      p="1rem"
      bgcolor="customColor.grey.accent4"
      borderRadius="1rem"
      width="100%"
      textAlign="center"
      data-testid="timer"
    >
      <Box data-testid="CardCollection-text-time" sx={{ marginBottom: '0.5rem' }}>
        <Typography component="span" variant="subtitle2">
          {title}
        </Typography>
        <Typography component="span" variant="body2" style={{ paddingLeft: '0.5rem' }}>
          {transformDateToDisplay(date)}
        </Typography>
      </Box>
      <Box data-testid="CardCollection-text-countdown">
        <Countdown date={date} now={() => Date.now() + 7 * 60 * 60 * 1000} renderer={rendererCounter} />
      </Box>
    </Box>
  );
};

export default Timer;
