import { useState } from 'react';
import { getMerkleInfo, getMerkleWalletInfo } from '../api/ipfsService';
import { useWeb3React } from '@web3-react/core';
import useNftDropAction from '../smartcontract/hooks/useNftDropAction';
import { CollectionInfo } from 'redux/slices/collections/action';
import { ethers } from 'ethers';
import { MerkleWalletInfo } from 'api/types/NftTypes';
interface UseNftProps {
  collectionAddress: string;
}

type ClaimProps = {
  qty: number;
  proof: string[];
  price: number;
  unit: string;
  maxQty: number;
};

function useNftDrop({ collectionAddress }: UseNftProps) {
  const [claimProps, setClaimProps] = useState<ClaimProps>({
    qty: 1,
    proof: [],
    price: 0,
    unit: '',
    maxQty: 0,
  });
  const { verifyClaimMerkleProof, claimNft, getActiveClaimConditionsId, getClaimConditionById } = useNftDropAction({
    contract: collectionAddress,
  });
  const { active, account } = useWeb3React();

  const checkClaimable = async (collectionInfo: CollectionInfo, qty: number) => {
    try {
      if (!active)
        return {
          isValid: false,
        };
      const conditionNo = await getActiveClaimConditionsId();
      const conditionInfo = await getClaimConditionById(parseInt(conditionNo._hex, 16));
      const unit = conditionInfo[7];
      const price = ethers.utils.formatEther(ethers.BigNumber.from(conditionInfo[6]._hex));

      if (conditionInfo[5] === '0x0000000000000000000000000000000000000000000000000000000000000000') {
        setClaimProps({
          qty,
          proof: ['0x0000000000000000000000000000000000000000000000000000000000000000'],
          unit,
          price: +price,
          maxQty: 0,
        });
        return {
          isValid: true,
        };
      }
      const merkle = conditionInfo[5];
      const merkleIpfs = collectionInfo.merkle[merkle];
      const merkleInfo = await getMerkleInfo(merkleIpfs.replace('ipfs://', ''));
      const merkleWalletInfo = await getMerkleWalletInfo(
        merkleInfo.baseUri.replace('ipfs://', '') + `/${(account || '').substring(2, 4).toLowerCase()}` + '.json',
      );

      if (merkleWalletInfo.status !== 200)
        return {
          isValid: false,
        };

      const wlWallet: MerkleWalletInfo = merkleWalletInfo.data;

      const result = await verifyClaimMerkleProof(
        conditionNo,
        account || '',
        qty,
        wlWallet.proofs,
        wlWallet.entries[0].maxClaimable,
      );

      if (result[0]) {
        setClaimProps({
          qty,
          proof: wlWallet.proofs,
          unit,
          price: +price,
          maxQty: wlWallet.entries[0].maxClaimable,
        });

        return {
          isValid: true,
        };
      }

      return {
        isValid: false,
      };
    } catch (error) {
      return {
        isValid: false,
      };
    }
  };

  const claimNftDrop = async (qty: number) => {
    const result = await claimNft(
      account || '',
      qty,
      claimProps.proof,
      claimProps.price,
      claimProps.unit,
      claimProps.maxQty,
      false,
    );
    return result;
  };

  return { checkClaimable, claimNftDrop };
}

export default useNftDrop;
