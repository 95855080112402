import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useWeb3React } from '@web3-react/core';
import ConnectWalletButton from '../ConnectWalletButton';
import { Link, NavLink } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useSnackbar } from 'notistack';
import OsNftLogo from 'assets/images/os-nft-logo.svg';
import FaqPdf from 'assets/pdf/FAQ.pdf';
import { useAppDispatch } from 'redux/hook';
import { openWalletModal } from 'redux/slices/wallet';
import { copyText } from 'utils/copyText';

const pages = [
  { name: 'Home', path: '/', id: 'btn_home' },
  { name: 'My Collection', path: '/my-collection', id: 'btn_myCollection' },
  {
    name: 'FAQ',
    path: FaqPdf,
    id: 'btn_faq',
    isLink: true,
  },
];

const NavBar = () => {
  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { account, active } = useWeb3React();
  const dispatch = useAppDispatch();

  const toggleDrawer = (open: boolean) => () => {
    setAnchorIsOpen(open);
  };

  const handleOpenConnectWalletDialog = async () => {
    if (active) {
      await copyText(account || '');
      enqueueSnackbar('Copied');
    } else {
      dispatch(openWalletModal());
    }
  };

  const activeStyle = {
    opacity: '0.5',
  };

  const renderMobileHamberger = () => {
    return (
      <Box
        sx={{
          flexGrow: 0,
          display: { xs: 'flex', md: 'none' },
        }}
        marginLeft="auto"
        marginRight="0"
      >
        <IconButton
          data-testid="hamberger-button"
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor={'right'} open={anchorIsOpen} onClose={toggleDrawer(false)}>
          <Box
            data-testid="hamberger-content"
            sx={{ width: '200px', px: '12px' }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              <ListItem button sx={{ justifyContent: 'end' }} onClick={toggleDrawer(false)}>
                <ListItemIcon sx={{ justifyContent: 'end' }}>
                  <CloseIcon />
                </ListItemIcon>
              </ListItem>
              {pages.map((page, index) =>
                page.isLink ? (
                  <ListItem
                    id={page.id}
                    button
                    key={index}
                    component={'a'}
                    href={page.path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ListItemText primary={page.name} />
                  </ListItem>
                ) : (
                  <ListItem
                    id={page.id}
                    button
                    key={index}
                    component={NavLink}
                    activeStyle={activeStyle}
                    to={page.path}
                    exact={page.path === '/'}
                  >
                    <ListItemText primary={page.name} />
                  </ListItem>
                ),
              )}
              <ConnectWalletButton
                onClick={handleOpenConnectWalletDialog}
                style={{ width: '100%' }}
              ></ConnectWalletButton>
            </List>
          </Box>
        </Drawer>
      </Box>
    );
  };

  const renderDesktopMenu = () => {
    return (
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: 'none', md: 'flex' },
          justifyContent: 'flex-end',
        }}
      >
        {pages.map((page, index) =>
          page.isLink ? (
            <Button
              id={page.id}
              key={index}
              component={'a'}
              href={page.path}
              target="_blank"
              rel="noopener noreferrer"
              onClick={toggleDrawer(false)}
              sx={{
                my: 2,
                display: 'flex',
                justifyContent: 'center',
              }}
              variant="text"
            >
              {page.name}
            </Button>
          ) : (
            <Button
              id={page.id}
              component={NavLink}
              to={page.path}
              activeStyle={activeStyle}
              exact={page.path === '/'}
              key={index}
              onClick={toggleDrawer(false)}
              sx={{
                my: 2,
                display: 'flex',
                justifyContent: 'center',
              }}
              variant="text"
            >
              {page.name}
            </Button>
          ),
        )}
        <Box sx={{ ml: '32px' }}>
          <ConnectWalletButton id="btn_menuConnectWallet" onClick={handleOpenConnectWalletDialog}></ConnectWalletButton>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <AppBar position="static" sx={{ boxShadow: 'none' }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Link to={'/'}>
              <Box
                id="img_abcLogo_upper"
                component="img"
                alt="Logo"
                src={OsNftLogo}
                sx={{
                  height: '4rem',
                }}
              />
            </Link>
            {renderMobileHamberger()}
            {renderDesktopMenu()}
            <Box sx={{ flexGrow: 0 }}></Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
export default NavBar;
