import IpfsRequest from '../apiConfig/IpfsRequest';
import { CollectionInfo, MerkleInfo, NftInfo, MerkleWalletInfo } from './types/NftTypes';

export const getCollectionInfo = async (id: string): Promise<CollectionInfo> => {
  const response = await IpfsRequest.get<CollectionInfo>(`/${id}`);
  return response.data;
};

export const getNftInfo = async (id: string): Promise<NftInfo> => {
  const response = await IpfsRequest.get<NftInfo>(`/${id}`);
  return response.data;
};

export const getMerkleInfo = async (id: string): Promise<MerkleInfo> => {
  const response = await IpfsRequest.get<MerkleInfo>(`/${id}`);
  return response.data;
};

export const getMerkleWalletInfo = async (id: string): Promise<any> => {
  const response = await IpfsRequest.get<MerkleWalletInfo>(`/${id}`);
  return response;
};
