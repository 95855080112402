export const copyText = async (text: string) => {
  if (navigator.clipboard && navigator.permissions) {
    await navigator.clipboard.writeText(text);
  } else if (document.queryCommandSupported('copy')) {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
};
