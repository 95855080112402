import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store/configureStore';
import {
  CollectionInfo,
  fetchCollectionList,
  fetchSupplyCollectionInfo,
  SupplyCollectionInfo,
  fetchActiveClaimConditionInfo,
  ActiveConditionInfo,
} from './action';

const initialState: {
  collectionList: CollectionInfo[];
  supplyInfo: SupplyCollectionInfo;
  activeConditionInfo: ActiveConditionInfo;
  isLoading: boolean;
  isLoadingSupply: boolean;
  isLoadingActiveCondition: boolean;
  errorMessage?: string;
} = {
  collectionList: [],
  activeConditionInfo: {
    address: '',
    price: 0,
  },
  supplyInfo: {
    address: '',
    totalClaimed: 0,
    totalSupply: 0,
  },
  isLoading: false,
  isLoadingSupply: false,
  isLoadingActiveCondition: false,
  errorMessage: '',
};

//Slices
const CollectionsSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCollectionList.pending, state => {
        state.errorMessage = '';
        state.isLoading = true;
      })
      .addCase(fetchCollectionList.fulfilled, (state, { payload }) => {
        state.collectionList = payload;
        state.errorMessage = '';
        state.isLoading = false;
      })
      .addCase(fetchCollectionList.rejected, (state, { payload }: any) => {
        state.errorMessage = payload.errorMessage;
        state.isLoading = false;
      })
      .addCase(fetchSupplyCollectionInfo.pending, state => {
        state.errorMessage = '';
        state.isLoadingSupply = true;
      })
      .addCase(fetchSupplyCollectionInfo.fulfilled, (state, { payload }) => {
        state.supplyInfo = payload;
        state.errorMessage = '';
        state.isLoadingSupply = false;
      })
      .addCase(fetchSupplyCollectionInfo.rejected, (state, { payload }: any) => {
        state.errorMessage = payload.errorMessage;
        state.isLoadingSupply = false;
      })
      .addCase(fetchActiveClaimConditionInfo.pending, state => {
        state.errorMessage = '';
        state.isLoadingActiveCondition = true;
      })
      .addCase(fetchActiveClaimConditionInfo.fulfilled, (state, { payload }) => {
        state.activeConditionInfo = payload;
        state.errorMessage = '';
        state.isLoadingActiveCondition = false;
      })
      .addCase(fetchActiveClaimConditionInfo.rejected, (state, { payload }: any) => {
        state.errorMessage = payload.errorMessage;
        state.isLoadingActiveCondition = false;
      });
  },
});

export const collectionsSelector = (state: RootState) => state.collections;

export default CollectionsSlice.reducer;
