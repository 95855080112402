import type { Contract } from '@ethersproject/contracts';
import { getNftDropContract } from '../utils/contractHelper';
import { callTransaction } from '../utils/serviceHelper';

class NftDropServices {
  nftContract: Contract;

  constructor(contract: string) {
    this.nftContract = getNftDropContract(contract, null as any);
  }

  getContractURI = () => {
    return callTransaction(this.nftContract, 'contractURI', []);
  };

  getClaimConditions = () => {
    return callTransaction(this.nftContract, 'claimCondition', []);
  };

  getActiveClaimConditionsId = () => {
    return callTransaction(this.nftContract, 'getActiveClaimConditionId', []);
  };

  getClaimConditionById = (id: number) => {
    return callTransaction(this.nftContract, 'getClaimConditionById', [id]);
  };

  getTokenURI = (id: number) => {
    return callTransaction(this.nftContract, 'tokenURI', [id]);
  };

  getTokenName = () => {
    return callTransaction(this.nftContract, 'name', []);
  };

  getTokenSymbol = () => {
    return callTransaction(this.nftContract, 'symbol', []);
  };

  getOwnerOf = (id: number) => {
    return callTransaction(this.nftContract, 'ownerOf', [id]);
  };

  getTotalSupply = () => {
    return callTransaction(this.nftContract, 'totalSupply', []);
  };

  getNextTokenIdToClaim = () => {
    return callTransaction(this.nftContract, 'nextTokenIdToClaim', []);
  };

  getNextTokenIdToMint = () => {
    return callTransaction(this.nftContract, 'nextTokenIdToMint', []);
  };

  getBalanceOf = (address: string) => {
    return callTransaction(this.nftContract, 'balanceOf', [address]);
  };

  getTokenOfOwnerByIndex = (address: string, id: number) => {
    return callTransaction(this.nftContract, 'tokenOfOwnerByIndex', [address, id]);
  };

  verifyClaimMerkleProof = (conditionId: number, wallet: string, qty: number, proof: string[], maxQty: number) => {
    return callTransaction(this.nftContract, 'verifyClaimMerkleProof', [conditionId, wallet, qty, proof, maxQty]);
  };
}

export default NftDropServices;
