import { Box, Button, Typography, TextField } from '@mui/material';
import React, { useState } from 'react';
import CheckWlStatusModal from '../CheckWlStatusModal';
import { getNftWhitelist } from './whitelistList';
import { trackTapCheckWhitelist } from 'mixpanel';

type CheckWlCardProps = {
  contract: string;
  collectionName: string;
};

type CheckWlStatusModalType = {
  title?: string;
  desc?: string;
  isOpen: boolean;
};

const CheckWlCard = ({ contract, collectionName }: CheckWlCardProps) => {
  const [wlStatus, setWlStatus] = useState<CheckWlStatusModalType>({
    isOpen: false,
  });
  const [checkAddress, setCheckAddress] = useState<string>('');

  const handleCheckWl = () => {
    trackTapCheckWhitelist(contract, collectionName);
    const wlList = getNftWhitelist(contract);
    if (wlList.find(value => value === checkAddress.trim())) {
      setWlStatus({
        title: 'Congratulations!',
        desc: 'You Are The Whitelist',
        isOpen: true,
      });
    } else {
      setWlStatus({
        title: 'Sorry!',
        desc: 'You Are Not The Whitelist',
        isOpen: true,
      });
    }
  };

  return (
    <>
      <Box
        border="1px solid"
        borderColor="customColor.grey.accent3"
        p="1rem"
        bgcolor="background.paper"
        borderRadius="1rem"
        width="100%"
        data-testid="check-wl-card"
      >
        <Box mb="20px" p="0px" display="flex" flexDirection="column" sx={{ gap: '8px' }}>
          <Typography variant="subtitle2">Check Whitelist Here</Typography>
          <TextField
            inputProps={{ 'data-testid': 'CheckWlCard-input-address' }}
            fullWidth
            size="small"
            value={checkAddress}
            onChange={e => setCheckAddress(e.target.value)}
          />
          <Typography variant="body2">Please Enter Wallet Address</Typography>
        </Box>
        <Button
          data-testid="CheckWlCard-btn-check"
          onClick={handleCheckWl}
          variant="contained"
          fullWidth
          sx={{ borderRadius: '100px', height: '48px' }}
        >
          CHECK
        </Button>
      </Box>
      <CheckWlStatusModal
        isOpen={wlStatus.isOpen}
        title={wlStatus.title}
        desc={wlStatus.desc}
        handleClose={() => {
          setCheckAddress('');
          setWlStatus(prev => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />
    </>
  );
};

export default CheckWlCard;
