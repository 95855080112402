import { InjectedConnector } from '@web3-react/injected-connector';
import { Web3Provider } from '@ethersproject/providers';
import { chainId } from 'config';

export const injected = new InjectedConnector({
  supportedChainIds: [chainId],
});

export const getLibrary = (provider: any) => {
  return new Web3Provider(provider);
};
