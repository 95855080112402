import React, { useEffect, useState } from 'react';
import LayoutContainer from 'app/containers/LayoutContainer';
import CollectionCard from './components/CollectionCard';
import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { fetchMyNfts, NftInfo } from './services/myNftService';
import { useWeb3React } from '@web3-react/core';
import DialogNFT from './components/DialogNFT';
import NotFoundNFT from './components/NotFoundNFT';
import { trackVisitMyCollectionPage } from 'mixpanel';

const DEV_ADDRESS = process.env.REACT_APP_DEV_ADDRESS?.split(' ') ?? [];
const COLLECTION_ADDRESS = [process.env.REACT_APP_NFT_DROP_ADDRESS || '', ...DEV_ADDRESS];

export default function MyCollectionsPage() {
  const { account } = useWeb3React();
  const [myNfts, setMyNfts] = useState<NftInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [nftIndex, setNftIndex] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (account) getMyNFTs();
  }, [account]);

  useEffect(() => {
    trackVisitMyCollectionPage();
  }, []);

  const getMyNFTs = async () => {
    setMyNfts([]);
    setIsLoading(true);
    await Promise.all(
      COLLECTION_ADDRESS.map(async colAddress => {
        const results = await fetchMyNfts(account || '', colAddress);

        if (results.length > 0)
          setMyNfts(prev => [...prev, ...results.sort((prev, next) => next.tokenId - prev.tokenId)]);
      }),
    );
    setIsLoading(false);
  };

  const onOpenDialog = (index: number) => {
    setNftIndex(index);
    setOpenDialog(true);
  };

  const onCloseDialog = () => {
    setNftIndex(undefined);
    setOpenDialog(false);
  };
  return (
    <>
      <LayoutContainer pageTitle={'My NFT Collections'}>
        <Box bgcolor="background.grey">
          <Container fixed sx={{ marginTop: '1rem' }}>
            <Typography id="lbl_myCollectionName" variant="h4">
              My Collections
            </Typography>
            <Box sx={{ minHeight: '40vh' }}>
              {myNfts.length > 0 && (
                <Grid container spacing={2} columnSpacing="13px">
                  {myNfts.map((nft, index) => (
                    <Grid key={index} item xs={6} sm="auto" md="auto" xl="auto">
                      <CollectionCard nft={nft} nftIndex={index} onOpenDialog={onOpenDialog} />
                    </Grid>
                  ))}
                </Grid>
              )}
              {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '40vh' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Box marginTop="2rem" marginBottom="2rem" display="flex" justifyContent="center">
                  {myNfts.length > 0 ? (
                    <Box width="60%">
                      <Typography id="lbl_content_myCollection" variant="body2" textAlign="center">
                        great! :-)
                      </Typography>
                      <Typography id="lbl_content_myCollection" variant="body2" textAlign="center">
                        here all you have collect so far more to collect
                      </Typography>
                    </Box>
                  ) : (
                    <NotFoundNFT />
                  )}
                </Box>
              )}
            </Box>

            {/* )} */}
          </Container>
          {openDialog && typeof nftIndex === 'number' && (
            <DialogNFT open={openDialog} onClose={onCloseDialog} nft={myNfts[nftIndex]} />
          )}
        </Box>
      </LayoutContainer>
    </>
  );
}
