import {
  Typography,
  Box,
  ImageList,
  ImageListItem,
  DialogTitle,
  DialogContent,
  Dialog,
  Link,
  IconButton,
  Grid,
} from '@mui/material';
import ConnectWalletButton from 'app/components/ConnectWalletButton';
import React, { useEffect } from 'react';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory, useLocation } from 'react-router-dom';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { injected } from 'smartcontract/utils/connector';
import { isMobile } from 'react-device-detect';
import { chainId } from 'config';
import { useAppSelector, useAppDispatch } from 'redux/hook';
import { closeWalletModal } from 'redux/slices/wallet';

declare const window: Window &
  typeof globalThis & {
    ethereum: any;
  };

const generateMobileMetamask = () => `https://metamask.app.link/dapp/` + window.location.href.replace('https://', '');

const ConnectWalletDialog = () => {
  const location = useLocation();
  const history = useHistory();
  const { activate, active, setError } = useWeb3React();
  const { isOpenConnectModal } = useAppSelector(state => state.wallet);
  const dispatch = useAppDispatch();

  const handleCloseDialog = () => dispatch(closeWalletModal());

  const connectWalletHandler = async () => {
    if (window.ethereum) {
      await activate(injected, async error => {
        if (error instanceof UnsupportedChainIdError) {
          try {
            setError(error);
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: `0x${chainId.toString(16)}` }],
            });
            activate(injected);
          } catch (error) {}
        }
      });
      handleCloseDialog();
    } else {
      if (isMobile) {
        window.open(generateMobileMetamask());
      } else {
        window.open('https://metamask.io/download/', '_blank');
      }
    }
  };

  const handleClose = () => {
    handleCloseDialog();
  };

  useEffect(() => {
    if (active) handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <Dialog
      data-testid="connect-wallet-dialog"
      open={isOpenConnectModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container direction="row" justifyContent="space-between">
          <Typography variant="h6">
            <AccountBalanceWalletIcon /> Select a Wallet
          </Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography>Please select a wallet to connect to this dapp:</Typography>
        <Box display="flex" justifyContent="center" alignItems="center" mt="1rem" mb="1rem">
          <ImageList variant="standard" cols={1} gap={1}>
            <ImageListItem key={1} cols={1} rows={1}>
              <img alt={'metamast-wallet'} src={'/assets/MetaMask_Fox.svg'} style={{ width: 48 }} />
            </ImageListItem>
          </ImageList>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            MetaMask
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <ConnectWalletButton onClick={connectWalletHandler}></ConnectWalletButton>
        </Box>
        <Box sx={{ m: '1rem' }} />
        <Typography component={'span'} color="primary">
          What is wallet?
        </Typography>
        <Box sx={{ m: '1rem' }} />
        <Typography component={'span'} fontSize={12}>
          Wallet are used to send, receive, and store digital assets like Ether. Wallets come in many forms. They are
          either built into your browser, an extension added to your browser, a piece of hardware plugged into your
          computer or even an app on your phone. For more information about the wallets,&nbsp;
          <Link
            underline="hover"
            href="https://docs.ethhub.io/using-ethereum/wallets/intro-to-ethereum-wallets/"
            target="_blank"
          >
            see this explanation
          </Link>
          .
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default ConnectWalletDialog;
