import { ethers } from 'ethers';
import { useNftContract } from './useContract';
import useTransaction from './useTransaction';

const useNftDropAction = ({ contract }: { contract: string }) => {
  const { sendTransaction, callTransaction } = useTransaction();
  const nftContract = useNftContract(contract);

  const claimNft = async (
    wallet: string,
    qty: number,
    proof: string[],
    price: number,
    unit: string,
    maxQty: number,
    isWaiting: boolean,
  ) => {
    return await sendTransaction(
      nftContract,
      'claim',
      [wallet, qty, unit, ethers.utils.parseEther(price + ''), proof, maxQty],
      {
        value: ethers.utils.parseEther(price + ''),
      },
      isWaiting,
    );
  };

  const getContractURI = async () => {
    return await callTransaction(nftContract, 'contractURI', []);
  };

  const getClaimConditions = async () => {
    return await callTransaction(nftContract, 'claimCondition', []);
  };

  const getActiveClaimConditionsId = async () => {
    return await callTransaction(nftContract, 'getActiveClaimConditionId', []);
  };

  const getClaimConditionById = async (id: number) => {
    return await callTransaction(nftContract, 'getClaimConditionById', [id]);
  };

  const getTokenURI = async (id: number) => {
    return await callTransaction(nftContract, 'tokenURI', [id]);
  };

  const getTokenName = async () => {
    return await callTransaction(nftContract, 'name', []);
  };

  const getTokenSymbol = async () => {
    return await callTransaction(nftContract, 'symbol', []);
  };

  const getOwnerOf = async (id: number) => {
    return await callTransaction(nftContract, 'ownerOf', [id]);
  };

  const getTotalSupply = async () => {
    return await callTransaction(nftContract, 'totalSupply', []);
  };

  const getBalanceOf = async (address: string) => {
    return await callTransaction(nftContract, 'balanceOf', [address]);
  };

  const getTokenOfOwnerByIndex = async (address: string, id: number) => {
    return await callTransaction(nftContract, 'tokenOfOwnerByIndex', [address, id]);
  };

  const verifyClaimMerkleProof = async (
    conditionId: number,
    wallet: string,
    qty: number,
    proof: string[],
    maxQty: number,
  ) => {
    return await callTransaction(nftContract, 'verifyClaimMerkleProof', [conditionId, wallet, qty, proof, maxQty]);
  };

  return {
    getContractURI,
    getClaimConditions,
    getTokenURI,
    getTokenName,
    getTokenSymbol,
    getOwnerOf,
    getTotalSupply,
    getTokenOfOwnerByIndex,
    getBalanceOf,
    getClaimConditionById,
    claimNft,
    getActiveClaimConditionsId,
    verifyClaimMerkleProof,
  };
};

export default useNftDropAction;
