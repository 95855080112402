import { Container, Grid } from '@mui/material';
import ClaimItemCard from 'app/pages/CollectionPage/components/ClaimItemCard';
import CheckWlCard from '../CheckWlCard';

type ClaimItemsSectionProps = {
  contract: string;
  itemImg: string;
  name: string;
  creator: string;
  totalClaimed: number;
  totalSupply: number;
  startDate: Date;
};

const ClaimItemsSection = ({
  contract,
  itemImg,
  name,
  creator,
  totalClaimed,
  totalSupply,
  startDate,
}: ClaimItemsSectionProps) => {
  return (
    <Container>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6} sm={4} md={3}>
          <ClaimItemCard
            isClaim={startDate.getTime() < Date.now() + 7 * 60 * 60 * 1000}
            contract={contract}
            itemImg={itemImg}
            name={name}
            creator={creator}
            totalClaimed={totalClaimed}
            totalSupply={totalSupply}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <CheckWlCard contract={contract} collectionName={name} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ClaimItemsSection;
