import { getCollectionInfo, getNftInfo } from 'api/ipfsService';
import NftDropServices from 'smartcontract/service/nftDropServices';
import { ipfsToHttp } from 'utils/ipfs-util';

export type NftInfo = {
  contract: string;
  name: string;
  owner: string;
  tokenId: number;
  image: string;
  animationUrl?: string;
  ipfs: string;
  description?: string;
  collectionName: string;
  creator: string;
};

export const fetchMyNfts = async (wallet: string, contract: string) => {
  try {
    let response: NftInfo[] = [];

    const contractInstance = new NftDropServices(contract);
    const [balance, uri] = await Promise.all([
      contractInstance.getBalanceOf(wallet),
      contractInstance.getContractURI(),
    ]);

    const collectionInfo = await getCollectionInfo(uri.replace('ipfs://', ''));

    const splitName = collectionInfo.name.split('-');

    const loop = parseInt(balance._hex, 16);

    await Promise.all(
      Array(loop)
        .fill(0)
        .map(async (_, index) => {
          const nftId = await contractInstance.getTokenOfOwnerByIndex(wallet, index);
          const nftIpfs = await contractInstance.getTokenURI(parseInt(nftId._hex, 16));
          const nftInfo = await getNftInfo(nftIpfs.replace('ipfs://', ''));
          response.push({
            contract,
            name: nftInfo.name,
            owner: wallet,
            tokenId: parseInt(nftId._hex, 16),
            image: ipfsToHttp(nftInfo.image),
            animationUrl: nftInfo.animation_url ? ipfsToHttp(nftInfo.animation_url) : nftInfo.animation_url,
            ipfs: nftInfo.image.replace('ipfs://', ''),
            description: nftInfo.description,
            creator: splitName?.[1]?.trim() || '',
            collectionName: splitName?.[0]?.trim() || collectionInfo.name,
          });
        }),
    );

    return response;
  } catch (error: any) {
    return [];
  }
};
