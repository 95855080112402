import React from 'react';
import { Box } from '@mui/material';
import OpenseaSVG from 'assets/images/opensea.svg';

function OpenSeaIcon() {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '50%',
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box component="img" src={OpenseaSVG} alt="openseaIcon" />
    </Box>
  );
}

export default OpenSeaIcon;
