import { Box, Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import NFTModal from '../NFTModal';
import useNftDrop from 'hooks/useNftDrop';
import { useWeb3React } from '@web3-react/core';
import { useAppSelector, useAppDispatch } from 'redux/hook';
import { openWalletModal } from 'redux/slices/wallet';
import CircularProgress from '@mui/material/CircularProgress';
import NotWhitelistModal from '../NotWhitelistModal';
import TransactionErrorModal from '../TransactionErrorModal';
import InProgressModal from '../InProgressModal';
import SuccessClaimModal from '../SuccessClaimModal';
import { fetchSupplyCollectionInfo } from 'redux/slices/collections/action';
import { trackTapMint } from 'mixpanel';
import NftDropServices from 'smartcontract/service/nftDropServices';
import { getNftWhitelist } from '../CheckWlCard/whitelistList';

type ClaimItemsSectionProps = {
  contract: string;
  itemImg: string;
  name: string;
  creator: string;
  totalClaimed: number;
  totalSupply: number;
  isClaim: boolean;
};

type LoadingModalType = {
  titleStep: string;
  middleDesc?: string;
  descStep: string;
  isOpen: boolean;
};

const ClaimItemCard = ({
  contract,
  itemImg,
  name,
  creator,
  totalClaimed,
  totalSupply,
  isClaim,
}: ClaimItemsSectionProps) => {
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openNotWl, setOpenNotWl] = useState<boolean>(false);
  const [openTransactionError, setOpenTransactionError] = useState<LoadingModalType>({
    titleStep: '',
    descStep: '',
    isOpen: false,
  });
  const [openLoadingModal, setOpenLoadingModal] = useState<LoadingModalType>({
    titleStep: '',
    descStep: '',
    isOpen: false,
  });
  const [openSuccessModal, setOpenSucsessModal] = useState<boolean>(false);
  const [loadingClaim, setLoadingClaim] = useState<boolean>(false);
  const [balanceUser, setBalanceUser] = useState<number>(0);
  const [loadingBalance, setLoadingBalance] = useState<boolean>(false);

  const { active, account } = useWeb3React();
  const { collectionList, isLoadingSupply, activeConditionInfo, isLoadingActiveCondition } = useAppSelector(
    state => state.collections,
  );
  const dispatch = useAppDispatch();
  const { checkClaimable, claimNftDrop } = useNftDrop({
    collectionAddress: contract,
  });

  const checkBalance = async () => {
    setLoadingBalance(true);
    const contractInstance = new NftDropServices(contract);
    const balance = await contractInstance.getBalanceOf(account || '');
    const balanceAmount = parseInt(balance._hex, 16);
    setBalanceUser(balanceAmount);
    setLoadingBalance(false);
  };

  useEffect(() => {
    if (active) {
      checkBalance();
    } else {
      setBalanceUser(0);
    }
  }, [active, account]);

  const handleCheckClaim = async () => {
    if (active) {
      trackTapMint(contract, name);
      setLoadingClaim(true);
      const collectionInfo = collectionList.find(collection => collection.address === contract);
      if (collectionInfo) {
        const check = await checkClaimable(collectionInfo, 1);

        if (check.isValid) {
          setOpenConfirm(true);
        } else {
          setOpenNotWl(true);
        }
        setLoadingClaim(false);
      }
    } else {
      dispatch(openWalletModal());
    }
  };
  const handleClaim = async () => {
    try {
      setOpenConfirm(false);
      setOpenLoadingModal({
        isOpen: true,
        titleStep: 'Minting Requested',
        descStep: 'Please Confirm Minting in Wallet',
      });
      const raw = await claimNftDrop(1);

      if (raw?.hash) {
        setOpenLoadingModal({
          isOpen: true,
          titleStep: 'In Process',
          middleDesc: 'This May Take 1-5 Minutes',
          descStep: `Please Don’t Close The Window`,
        });
        const response = await raw.wait();
        setOpenLoadingModal({ isOpen: false, titleStep: '', descStep: '' });
        if (response?.transactionHash && response?.status === 1) {
          setOpenSucsessModal(true);
          checkBalance();
          dispatch(fetchSupplyCollectionInfo({ contract }));
        }
      } else {
        setOpenLoadingModal({ isOpen: false, titleStep: '', descStep: '' });
        if (raw.split(' [')?.[0]?.trim() === 'insufficient funds for intrinsic transaction cost')
          setOpenTransactionError({
            isOpen: true,
            titleStep: 'Error!',
            descStep: 'You have insufficient balance to claim and pay gas fee or You have reached claim limitation',
          });
      }
    } catch (error) {
      setOpenLoadingModal({ isOpen: false, titleStep: '', descStep: '' });
    }
  };

  const displayPrice = () => {
    if (isLoadingActiveCondition) {
      return '-';
    } else {
      return activeConditionInfo.price > 0 ? `${activeConditionInfo.price} ETH` : 'Free Airdrop';
    }
  };

  const isMaxClaim = () => {
    const wlList = getNftWhitelist(contract);
    if (wlList.find(value => value === account || '')) return balanceUser >= 25;
    return balanceUser >= 20;
  };

  return (
    <>
      <Box
        border="1px solid"
        borderColor="customColor.grey.accent3"
        p="1rem"
        bgcolor="background.paper"
        borderRadius="1rem"
        width="100%"
        data-testid="claim-item-card"
      >
        <Box sx={{ height: { xs: '140px', sm: '240px' } }} marginBottom="1rem">
          <img
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '1rem',
            }}
            src={itemImg}
            srcSet={itemImg}
            alt="claim-nft"
            loading="lazy"
          />
        </Box>
        <Typography data-testid="ClaimItemCard-text-name" variant="subtitle2">
          {name}
        </Typography>
        <Typography
          data-testid="ClaimItemCard-text-supply"
          variant="subtitle2"
          sx={{ color: 'customColor.grey.accent2' }}
        >
          {!isLoadingSupply && totalClaimed === totalSupply && `( ${totalClaimed - 1} / ${totalSupply - 1} )`}
        </Typography>
        <Box display="flex" flexDirection="column" gap="0.5rem" marginTop="0.5rem" marginBottom="1rem">
          <Typography variant="body2">Price</Typography>
          <Typography variant="body2" data-testid="ClaimItemCard-text-price">
            {displayPrice()}
          </Typography>
        </Box>

        <Button
          data-testid="ClaimItemCard-btn-claim"
          onClick={handleCheckClaim}
          variant="contained"
          fullWidth
          sx={{ borderRadius: '100px', height: '48px' }}
          disabled={
            !isClaim ||
            totalClaimed === totalSupply ||
            isLoadingSupply ||
            loadingClaim ||
            isLoadingActiveCondition ||
            loadingBalance ||
            isMaxClaim()
          }
        >
          {loadingClaim ? <CircularProgress size="24px" /> : totalClaimed === totalSupply ? 'SOLD OUT' : 'MINT'}
        </Button>
      </Box>
      <NotWhitelistModal isOpen={openNotWl} handleClose={() => setOpenNotWl(false)} />
      <NFTModal
        isOpen={openConfirm}
        handleClose={() => setOpenConfirm(false)}
        handleClaim={handleClaim}
        itemImg={itemImg}
        name={name}
        price={activeConditionInfo.price}
        creator={creator}
        totalClaimed={totalClaimed}
        totalSupply={totalSupply}
      />
      <InProgressModal
        isOpen={openLoadingModal.isOpen}
        title={openLoadingModal.titleStep}
        desc={openLoadingModal.descStep}
        middleDesc={openLoadingModal?.middleDesc}
      />
      <TransactionErrorModal
        title={openTransactionError.titleStep}
        desc={openTransactionError.descStep}
        isOpen={openTransactionError.isOpen}
        handleClose={() =>
          setOpenTransactionError(prev => ({
            ...prev,
            isOpen: false,
          }))
        }
      />
      <SuccessClaimModal isOpen={openSuccessModal} handleClose={() => setOpenSucsessModal(false)} />
    </>
  );
};

export default ClaimItemCard;
