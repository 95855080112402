import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCollectionInfo } from 'api/ipfsService';
import { ethers } from 'ethers';
import NftDropServices from 'smartcontract/service/nftDropServices';
import { META_DATA, THAILAND_GAME_SHOW } from './Metadata';
import { ipfsToHttp } from 'utils/ipfs-util';

export type CollectionInfo = {
  address: string;
  name: string;
  bgDesktopImage: string;
  bgMobileImage: string;
  avatarImage?: string;
  cliamImage?: string;
  symbol: string;
  description?: string;
  publicDate?: Date;
  privateDate?: Date;
  by: string;
  rarity: {
    img: string;
    name: string;
    level: string;
    amount: number;
  }[];
  merkle: {
    [key: string]: string;
  };
};

export type SupplyCollectionInfo = {
  address: string;
  totalClaimed: number;
  totalSupply: number;
};

export type ActiveConditionInfo = {
  address: string;
  price: number;
  claimDate?: Date;
};

function EpochToDate(epoch) {
  if (epoch < 10000000000) epoch *= 1000;
  return new Date(epoch);
}

const fetchCollectionInfo = async (contract, tempData) => {
  const contractInstance = new NftDropServices(contract);
  const uri = await contractInstance.getContractURI();

  const collectionInfo = await getCollectionInfo(uri.replace('ipfs://', ''));
  const privateDate = EpochToDate(1666234800 * 1000);
  const publicDate = EpochToDate(1666321200 * 1000);

  const splitName = collectionInfo.name.split('-');

  const metaData = META_DATA[contract];

  tempData.push({
    address: contract,
    name: splitName?.[0]?.trim() || collectionInfo.name,
    bgDesktopImage: metaData ? metaData.bgDesktopImage : THAILAND_GAME_SHOW.bgDesktopImage,
    bgMobileImage: metaData ? metaData.bgMobileImage : THAILAND_GAME_SHOW.bgMobileImage,
    avatarImage: ipfsToHttp(collectionInfo.image),
    cliamImage: metaData ? metaData.cliamImage : THAILAND_GAME_SHOW.cliamImage,
    symbol: collectionInfo.symbol,
    description: collectionInfo?.description,
    by: splitName?.[1]?.trim() || '',
    rarity: metaData ? metaData.rarity : THAILAND_GAME_SHOW.rarity,
    publicDate: new Date(publicDate.setTime(publicDate.getTime() + 7 * 60 * 60 * 1000)),
    privateDate: new Date(privateDate.setTime(privateDate.getTime() + 7 * 60 * 60 * 1000)),
    merkle: collectionInfo.merkle,
  });
};

export const fetchCollectionList = createAsyncThunk(
  'collections/fetchCollectionList',
  async ({ contracts }: { contracts: string[] }, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      const existingState: CollectionInfo[] = state?.collections?.collectionList;
      let response: CollectionInfo[] = Object.assign([], existingState);
      const findList = contracts.filter(contract => {
        return !existingState.find(value => value.address === contract);
      });
      await Promise.all(findList.map(async contract => await fetchCollectionInfo(contract, response)));
      return response;
    } catch (error: any) {
      return rejectWithValue({
        errorMessage: JSON.stringify(error),
      });
    }
  },
);

export const fetchSupplyCollectionInfo = createAsyncThunk(
  'collections/fetchSupplyCollectionInfo',
  async ({ contract }: { contract: string }, { rejectWithValue }) => {
    try {
      const contractInstance = new NftDropServices(contract);
      const [supplyContract, supplyClaimed] = await Promise.all([
        contractInstance.getNextTokenIdToMint(),
        contractInstance.getNextTokenIdToClaim(),
      ]);

      const response: SupplyCollectionInfo = {
        address: contract,
        totalClaimed: parseInt(supplyClaimed._hex, 16),
        totalSupply: parseInt(supplyContract._hex, 16),
      };
      return response;
    } catch (error: any) {
      return rejectWithValue({
        errorMessage: JSON.stringify(error),
      });
    }
  },
);

const getActiveClaimId = async (contractInstance: any) => {
  try {
    const activeCliamId = await contractInstance.getActiveClaimConditionsId();
    return parseInt(activeCliamId._hex, 16);
  } catch (error) {
    return 0;
  }
};
export const fetchActiveClaimConditionInfo = createAsyncThunk(
  'collections/fetchActiveClaimConditionInfo',
  async ({ contract }: { contract: string }, { rejectWithValue }) => {
    try {
      const contractInstance = new NftDropServices(contract);
      const conditionId = await getActiveClaimId(contractInstance);
      const conditionInfo = await contractInstance.getClaimConditionById(conditionId === 0 ? 1 : conditionId);
      return {
        address: contract,
        price: +ethers.utils.formatEther(ethers.BigNumber.from(conditionInfo[6]._hex)),
        claimDate: EpochToDate(parseInt(conditionInfo[0]._hex, 16) * 1000),
      };
    } catch (error: any) {
      return rejectWithValue({
        errorMessage: JSON.stringify(error),
      });
    }
  },
);
