import React, { ReactNode } from 'react';
import { alpha, Button, Typography } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import { Variant } from '@mui/material/styles/createTypography';

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: '100px',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.7),
  },
}));

export default function MainButton({
  children,
  onClick,
  sx,
  textVariant,
  ...props
}: {
  children: ReactNode;
  onClick: () => void;
  sx?: SxProps;
  textVariant?: Variant;
}) {
  return (
    <CustomButton variant="contained" onClick={onClick} sx={sx} {...props}>
      <Typography variant={textVariant || 'button'} sx={{ color: '#FFFFFF' }}>
        {children}
      </Typography>
    </CustomButton>
  );
}
