import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography, { TypographyProps } from '@mui/material/Typography';
import OpenSeaIcon from '../Icon/OpenSeaIcon';

interface Props {
  contract: string;
  tokenId: string | number;
  buttonProps?: ButtonProps;
  typographyProps?: TypographyProps;
}

function OpenSeaButton({ contract, tokenId, buttonProps, typographyProps }: Props) {
  const navigateToOpenSea = () => {
    (window as any).open(`${process.env.REACT_APP_OPENSEA_URL}/${contract}/${tokenId}`, '_blank');
  };
  return (
    <Button onClick={navigateToOpenSea} variant="contained" fullWidth endIcon={<OpenSeaIcon />} {...buttonProps}>
      <Typography component={'span'} fontSize={14} fontWeight={600} {...typographyProps}>
        SEE ON
      </Typography>
    </Button>
  );
}

export default OpenSeaButton;
