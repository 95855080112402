import { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import IpfsAPI from './IpfsAPI';

const request = <TRequest = any, TResponse = any>(
  method: Method = 'get',
  url: string,
  options: AxiosRequestConfig = {},
  data?: TRequest,
) => {
  const config: AxiosRequestConfig = {
    url,
    method,
    data,
    ...options,
  };
  return IpfsAPI.request<TResponse>(config);
};

const api = {
  get: <TResponse = any>(url: string, requestOptions?: AxiosRequestConfig): Promise<AxiosResponse<TResponse>> => {
    return request<any, TResponse>('get', url, requestOptions);
  },
};

export default api;
