import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import { NftInfo } from 'app/pages/MyCollectionsPage/services/myNftService';
import Image from 'app/components/Image';
import OpenSeaButton from 'app/components/OpenSeaButton';
import VideoIcon from 'assets/images/video.svg';

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '12px 12px 14px 12px',
  marginTop: '1rem',
  color: theme.palette.text.secondary,
  borderRadius: '1rem',
  textOverflow: 'ellipsis',
  minWidth: 0,
  height: 'max-content',
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'max-content',
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: 165,
  },
}));
interface Props {
  nft: NftInfo;
  onOpenDialog: (index: number) => void;
  nftIndex: number;
}

function CollectionCard({ nft, onOpenDialog, nftIndex }: Props) {
  return (
    <Item data-testid="CollectionCard-box">
      <Box onClick={() => onOpenDialog(nftIndex)}>
        <Box height="140px" position="relative">
          <Image src={nft.image} alt={nft.name} />
          {nft.animationUrl && (
            <Box
              data-testid="CollectionCard-icon-video"
              alt="video icon"
              component="img"
              src={VideoIcon}
              sx={{
                position: 'absolute',
                right: '11px',
                top: '11px',
                height: '18px',
                width: '18px',
                boxShadow: 1,
                borderRadius: '50%',
              }}
            />
          )}
        </Box>
        <Typography data-testid="CollectionCard-text-name" variant="subtitle2" mt="18px" mb={1}>
          {nft.name}
        </Typography>
        <Typography data-testid="CollectionCard-text-collection" variant="subtitle2" fontSize={12} mb={1}>
          {nft.collectionName}
        </Typography>
        <Typography
          data-testid="CollectionCard-text-creator"
          variant="subtitle2"
          fontSize={12}
        >{`by ${nft.creator}`}</Typography>
      </Box>
      <OpenSeaButton
        contract={nft.contract}
        tokenId={nft.tokenId}
        buttonProps={{ sx: { mt: '10px', py: '8px', borderRadius: '100px', height: '40px' } }}
      />
    </Item>
  );
}

export default CollectionCard;
