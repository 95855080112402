import React from 'react';

const Image = (props: React.ImgHTMLAttributes<HTMLImageElement>) => (
  <img
    src={props.src}
    alt={props.alt}
    loading="lazy"
    {...props}
    style={{
      minWidth: 0,
      maxWidth: '100%',
      width: '100%',
      height: '100%',
      margin: 0,
      borderRadius: 16,
      objectFit: 'cover',
      ...props.style,
    }}
  />
);

export default Image;
