import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Container, Typography } from '@mui/material';
import OsNftLogo from 'assets/images/os-nft-logo.svg';
import TernAndConditionPdf from 'assets/pdf/NFT-Dropsite-Website-T&Cs.pdf';
import PrivacyNoticePdf from 'assets/pdf/Privacy-Notice-Online-Station.pdf';
import FaqPdf from 'assets/pdf/FAQ.pdf';

const pages = [
  { name: 'FAQ', id: 'btn_footer_faq', file: FaqPdf },
  { name: 'Terms and Condition', id: 'btn_footer_termAndCondition', file: TernAndConditionPdf },
  { name: 'Privacy Notice', id: 'btn_footer_privacyNotice', file: PrivacyNoticePdf },
];

export default function BottomAppBar() {
  return (
    <AppBar position="static" color="primary">
      <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'space-between', my: '56px' }}>
        <Box display="flex" flexDirection="column">
          <Box
            id="img_abcLogo_footer"
            component="img"
            sx={{
              height: '32px',
              width: '72px',
            }}
            alt="Logo"
            src={OsNftLogo}
          />
          <Box
            flex="1"
            display="flex"
            flexDirection="column"
            sx={{ justifyContent: { xs: 'flex-end', md: 'flex-start' } }}
          >
            <Typography color="primary.main" variant="subtitle2" sx={{ paddingLeft: '8px' }}>
              Support
            </Typography>
            <Button
              sx={{
                lineHeight: '1.25rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
              href="mailto:os-nft-support@truedigital.com"
            >
              os-nft-support
              <Typography component="span" sx={{ display: { xs: 'block', md: 'none' } }}>
                {' '}
              </Typography>
              @truedigital.com
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            gap: '20px',
          }}
          alignItems="flex-end"
          display="flex"
          flexDirection="column"
        >
          {pages.map(page => (
            <Button
              id={page.id}
              key={page.name}
              sx={{ lineHeight: '1rem', textAlign: 'end' }}
              href={page.file}
              target="_blank"
              rel="noopener noreferrer"
            >
              {page.name}
            </Button>
          ))}
        </Box>
      </Container>
    </AppBar>
  );
}
